.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.tableContainer {
    border: 1px solid var(--color-gray2);
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
}

.tableContainer th {
    width: 30%;
}
