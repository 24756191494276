.promptListContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.promptDetailContainer {
    margin: 0 auto;
}

.snackbarContainer {
    z-index: 10000;
}

.emptyContainer {
    text-align: center;
}
