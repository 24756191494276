.resultArea {
    width: 35%;
    padding: 20px;
    height: auto;
    position: absolute;
    right: 0;
    top: 0;
    display: inline-flex;
    flex-direction: column;
    border: 1px solid var(--color-gray5);
    background-color: var(--color-gray1);
    word-break: keep-all;
}

.refreshButton {
    outline: none;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 0 auto;
    background-color: var(--white);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.refreshButton:hover {
    box-shadow: 0px 0px 5px 0px rgba(84, 63, 214, 0.4);
}

.title {
    margin: 0 auto;
}