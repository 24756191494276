.container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.animationContainer {
    width: 100%;
    max-width: 700px;
    height: auto;
    margin-bottom: 20px;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    max-width: 800px;
    width: 100%;
}

.contentContainer h1 {
    font-size: 60px;
    font-weight: 800;
    font-family: "Inter", sans-serif;
    margin-bottom: 0;
}

.contentContainer p {
    font-size: 30px;
    font-family: "Inter", sans-serif;
}
.buttonContainer {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
}
.buttonContainer p {
    font-size: 24px;
    font-family: "Inter", sans-serif;
}
@media (max-width: 768px) {
    .container {
        justify-content: flex-start;
        padding-top: 50px;
    }

    .animationContainer {
        max-width: 300px;
    }

    .contentContainer {
        padding-left: 30px;
        padding-right: 30px;
    }
    .contentContainer h1 {
        padding-left: 30px;
        padding-right: 30px;

        font-size: 45px;
        font-weight: 800;
    }
    .contentContainer p {
        font-size: 20px;
    }
    .buttonContainer {
        flex-direction: column;
        align-items: stretch;
    }
    .buttonContainer p {
        font-size: 18px;
        font-family: "Inter", sans-serif;
    }
}
