.container {
    width: 390px;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.3);
    background-color: var(--color-gray1);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
}

.logo {
    margin: 20px;
    width: 145px;
}

.listContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: auto; /* 스크롤 가능하게 설정 */
    flex-grow: 1; /* 남은 공간을 차지하도록 설정 */
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

input {
    box-sizing: border-box;
    background-color: var(--color-gray2);
    border: none;
    padding: 15px 18px;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    caret-color: var(--block-main-color);
}
