.pagination {
    display: flex;
    justify-content: center;
    list-style: none;

    padding: 3vw;
}

.pagination button {
    background-color: #fff;
    border: 1px solid #ddd;
    color: #333;
    padding: 8px 16px;
    margin: 0 4px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.pagination button:hover:not(.active) {
    background-color: #f5f5f5;
}

.pagination .active {
    background-color: #543FD6;
    color: white;
    border-color: #543FD6;
}

.pagination button:disabled {
    background-color: #f8f9fa;
    color: #6c757d;
    cursor: default;
}
