.topSection {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    text-align: center;
    margin-bottom: 5vh;
    height: 7vh;
}

.methodSection {
    grid-column: 1;
    justify-self: start;
    text-align: center;
}

.likeSection {
    grid-column: 2;
    justify-self: center;

    display: flex;
    flex-direction: column;
    text-align: center;
}

.likeButtonSection {
    display: flex;
    justify-content: center;
    justify-items: center;
}

.likeButton {
    display: flex;
    cursor: pointer;
}

.closeSection {
    display: flex;
    grid-column: 3;
    justify-self: end;
}

.closeButton {
    width: fit-content;
    cursor: pointer;
}
