.container {
    width: 100%;
    overflow-x: hidden;
}

.categoryContainer {
    position: sticky;
}
.categoryScrollContainer {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    gap: 10px;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
    position: relative;
    background-color: var(--color-gray1);
    overflow-x: auto;
    top: 0;
    scroll-behavior: smooth;
}

.categoryContainer::-webkit-scrollbar {
    display: none;
}

.leftScrollIcon, .rightScrollIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 5px;
    background: radial-gradient(circle, var(--color-gray1) 30%, transparent 70%);
}

.leftScrollIcon {
    left: 10px;
}

.rightScrollIcon {
    right: 10px;
}

.categoryButton {
    padding: 5px 10px;
    border: 1.5px solid var(--color-gray4);
    background-color: #fff;
    cursor: pointer;
    border-radius: 6px;
    padding: 5px 10px;
    color: var(--color-gray6);
    white-space: nowrap;
    font-size: 16px;
}

.active {
    background-color: var(--block-main-color); /* 선택된 버튼 배경색 */
    color: var(--white);
    border: 1px solid var(--block-main-color);
    transition: 0.2s;
}

.promptListContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.promptListWrapper {
    overflow: auto;
    overflow-x: hidden;
}

.promptListWrapper::-webkit-scrollbar {
    width: 10px;
}
.promptListWrapper::-webkit-scrollbar-thumb {
    background-color: #543fd6;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
}
.promptListWrapper::-webkit-scrollbar-track {
    background-color: rgba(84, 63, 214, 0.5);
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
}

.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 10px;
}

.empty img {
    width: 35px;
}
