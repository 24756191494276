.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* 반투명한 배경 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

.container {
    display: flex;
    flex-direction: column;

    padding: 2vw;

    width: 800px;
    height: auto;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    z-index: 1102;
}

.textSection {
    gap: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.categorySection {

}

.explainSection {
    width: 100%;
    overflow: auto;
    word-break: break-all;
}

.explainSection::-webkit-scrollbar {
    width: 10px;
}
.explainSection::-webkit-scrollbar-thumb {
    background-color: #543fd6;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
}
.explainSection::-webkit-scrollbar-track {
    background-color: rgba(84, 63, 214, 0.5);
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
}

.previewSectionText {
    width: 100%;
    overflow: auto;
    word-break: break-all;
}

.previewSectionText::-webkit-scrollbar {
    width: 10px;
}
.previewSectionText::-webkit-scrollbar-thumb {
    background-color: #543fd6;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
}
.previewSectionText::-webkit-scrollbar-track {
    background-color: rgba(84, 63, 214, 0.5);
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
}

.blockSection {
    display: flex;
    flex-direction: column;
    margin: 3vh auto;
    height: auto;
}
