.container {
    width: 100%;
    height: 92.5vh;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.headerContainer {
    width: 100%;
    background-color: var(--logo-purple2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.header {
    font-size: 40px;
    font-weight: 500;
    color: white;
    margin: 0;
}

.line {
    width: 40px;
    border: none;
    height: 1.5px;
    background-color: white;
}

.headerContent {
    font-size: 20px;
    color: white;
    margin: 0;
}

.bodyContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 0 auto;
    width: 100%;
}

.contentContainer {
    width: 60%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 80px;
    gap: 60px;
}

.contentTitle {
    font-size: 30px;
    font-weight: 600;
    margin: 0;
}

.contentSubTitle {
    font-size: 25px;
    font-weight: 500;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.content {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
}

.buttonList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5px;
}

.linkBlack {
    width: 100%;
    border: none;
    height: 1px;
    background-color: var(--color-gray3);
}

.shortContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
