.container {
    display: flex;
    align-items: center;

    min-height: 100vh;
    background-color: #f3f4f6;
    padding: 1rem;
}

.alert {
    max-width: 28rem;
    width: 100%;
    background-color: #fef2f2;
    border: 1px solid #f87171;
    border-radius: 0.375rem;
    padding: 1rem;
    box-shadow:
        0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.icon {
    color: #ef4444;
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 0.5rem;
}

.title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #b91c1c;
    margin-bottom: 0.5rem;
}

.description {
    font-size: 0.875rem;
    color: #7f1d1d;
}

.mainMessage {
    margin-bottom: 1rem;
}

.subMessage {
    font-size: 0.75rem;
    font-style: italic;
}
