.wrapper {
    width: 330px;
    height: 250px;
    border-radius: 2vw;
}

.wrapper:hover .card {
    transform: rotateY(180deg);
    box-shadow: 0 0 5px -1px #007bff;
}

.card {
    text-align: center;
    width: 330px;
    height: 250px;
    border-radius: 2vw;
    box-shadow: 0 0 5px -1px;
    transition: all 0.7s;
    perspective-origin: center;
    transform-style: preserve-3d;
    cursor: pointer;
}

.containerFront {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    width: 330px;
    height: 250px;
    border-radius: 2vw;
    position: absolute;
    backface-visibility: hidden;
    z-index: 2;
    background-color: white;

    overflow: hidden;
}

.categoryBackgroundIcon {
    position: absolute;
    bottom: -30px;
    left: -40px;
    opacity: 0.1;
    z-index: 0;
    width: 200px;
    height: 200px;
    pointer-events: none;
    color: #6933d3;
}

.categoryBackgroundIcon svg {
    width: 100%;
    height: 100%;
}

.typeSection {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 20px 20px 20px auto;
}

.typeText {
    color: #5a5a5a;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 9.825px; /* 120% */
}

.categorieSection {
    gap: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: auto;
    height: 30px;
    padding: 0.1vh 0.5vw 0.1vh 0.5vw;
    border-radius: 0.3vw;
    background: #543fd6;

    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.descriptionSection {
    width: 280px;
    margin-top: 6vh;
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.descriptionSectionText {
    width: 100%;
    height: 5vh;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
}

.explainSection {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    height: 150px;
}

.backExplainSection {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    gap: 1vh;

    height: 120px;
    width: 250px;

    border-radius: 15px;
    border: 0.424px solid #5a5a5a;
    background: var(--white, #fff);

    padding: 20px;
}

.userSection {
    width: 80%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.likeSection {
    display: flex;
    align-items: center;
}

.likeSection svg {
    width: 20px;
    height: 20px;
}

.likeText {
    color: #000;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.containerBack {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    width: 330px;
    height: 250px;
    border-radius: 2vw;
    position: absolute;
    transform: rotateY(180deg);
    z-index: 1;
    background-color: white;
}

.promptPreviewContainer {
    width: 100%;
    height: 100%;
    overflow: auto;
    word-break: break-all;
}

.promptPreviewContainer::-webkit-scrollbar {
    width: 10px;
}
.promptPreviewContainer::-webkit-scrollbar-thumb {
    background-color: #543fd6;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
}
.promptPreviewContainer::-webkit-scrollbar-track {
    background-color: rgba(84, 63, 214, 0.5);
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
}
