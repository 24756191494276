.container {
    width: 30%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.promptCreateButton:hover {
    box-shadow: 0px 0px 10px 0px rgba(84, 63, 214, 0.3);
    transform: scale(1.1);
    transition: 0.2s;
}

.promptCreateButton:active {
    background-color: var(--color-gray2);
}

.promptCreateButton {
    background-color: var(--color-gray1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    display: flex;
    padding: 25px 15px;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    text-align: center;
    flex: 1;
    height: 100%;
}

.iconContainer {
    width: 30px;
}

.contentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
