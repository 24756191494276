.container {
    display: flex;
    justify-content: space-between; /* 요소 사이 공간을 최대로 벌림 */
    align-items: center;
    width: auto;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    cursor: pointer;
    padding: 15px;
}

.IconNName {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iconContainer {
    margin: 17px;
    display: flex;
    align-items: center;
}

.titleWrapper {
    max-width: 250px; /* 적절한 최대 너비 설정 */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.icon {
    width: 20px;
}
