.headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 7.5vh;
    background-color: var(--white);
    z-index: 10;
    padding: 0 20px;
    box-sizing: border-box;
}

.logoContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.navContainer {
    display: flex;
    align-items: center;
    gap: 20px;
}

.languageSelector {
    display: flex;
    align-items: center;
    gap: 5px;
}

.flagButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 24px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flagButton img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.flagButton:hover {
    opacity: 0.8;
}

.navItem {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #000;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.navItem:hover {
    color: #007bff;
}

.welcomeMessage {
    margin-right: 10px;
}

.menuButton {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.mobileMenu {
    display: none;
}
.languageSelector {
    display: flex;
    align-items: center;
}

.flagImage {
    width: 24px;
    height: 16px;
    margin-right: 8px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:checked + .slider:before {
    transform: translateX(20px);
}
@media (max-width: 768px) {
    .headerContainer {
        height: auto;
        padding: 10px;
        flex-wrap: wrap;
    }

    .logoContainer {
        margin-bottom: 10px;
    }

    .navContainer {
        width: 100%;
        justify-content: space-around;
    }
}

@media (max-width: 640px) {
    .navItem span {
        display: none;
    }

    .navItem {
        font-size: 24px;
    }

    .welcomeMessage {
        display: none;
    }
}

@media (max-width: 480px) {
    .headerContainer {
        height: 60px;
    }

    .centerLogo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .menuButton {
        display: block;
        position: absolute;
        left: 10px;
    }

    .navContainer {
        display: none;
    }

    .mobileMenu {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        background-color: var(--white);
        padding: 20px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        z-index: 100;
    }

    .mobileMenu .navItem {
        padding: 10px 0;
    }

    .mobileMenu .languageSelector {
        margin-top: 10px;
    }
}
