.formGroup {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.select {
  width: 100%;
}

.options {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 10px;
}

.option {
  list-style-type: none;
  padding: 6px 12px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.options .active {
  background-color: var(--block-main-color);
  border: 1px solid var(--block-main-color);
}

.options .none {
  background-color: var(--white);
  border: 1px solid var(--color-gray5);
}

input {
  box-sizing: border-box;
  background-color: var(--color-gray2);
  border: none;
  padding: 15px 18px;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  caret-color: var(--block-main-color);
}

.promptPreview {
  height: 100px;
  overflow-y: auto;
  padding: 0 10px;
  border: 1px solid var(--color-gray5);
}

.promptPreview::-webkit-scrollbar {
    width: 10px;
}
.promptPreview::-webkit-scrollbar-thumb {
    background-color: #543fd6;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
}
.promptPreview::-webkit-scrollbar-track {
    background-color: rgba(84, 63, 214, 0.5);
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
}
