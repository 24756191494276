.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.scrollContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    overflow-x: hidden;
}

.scrollContainer::-webkit-scrollbar {
    width: 10px;
}
.scrollContainer::-webkit-scrollbar-thumb {
    background-color: #543fd6;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
}
.scrollContainer::-webkit-scrollbar-track {
    background-color: rgba(84, 63, 214, 0.5);
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
}

.chattingListContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    width: 95%;
}

.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 10px;
}

.empty img {
    width: 35px;
}
