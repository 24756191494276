.listContainer {
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin: auto;

    width: 80%;
    height: 250px;

    overflow: auto;
}

.listContainer::-webkit-scrollbar {
    width: 10px;
}
.listContainer::-webkit-scrollbar-thumb {
    background-color: #543fd6;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
}
.listContainer::-webkit-scrollbar-track {
    background-color: rgba(84, 63, 214, 0.5);
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
}

.shareButton {
    margin-top: 15px;

    display: inline-flex;
    height: 40px;
    padding: 1vh 4vw;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;


    border-radius: 3vw;
    border: 1px solid #543fd6;
    background: #543fd6;

    cursor: pointer;
}
