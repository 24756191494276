.promptDetailContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.promptBlockEditButton {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    padding: 0 3px;
    gap: 5px;
}

.promptBlockEditButton:hover {
    background-color: var(--color-gray1);
}

.promptBlockEditButton img {
    width: 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.select {
    width: 100%;
}

.options {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
    gap: 10px;
}

.option {
    list-style-type: none;
    padding: 6px 12px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.options .active {
    background-color: var(--block-main-color);
    border: 1px solid var(--block-main-color);
}

.options .none {
    background-color: var(--white);
    border: 1px solid var(--color-gray5);
}

input {
    box-sizing: border-box;
    background-color: var(--color-gray2);
    border: none;
    padding: 15px 18px;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    caret-color: var(--block-main-color);
}
