.filePreviewContainer {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
}

.filePreview {
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: var(--color-gray2);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.imageContainer {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px var(--color-gray3);
}

.previewImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.removeButton {
    position: relative;
    right: 18px;
}

.removeButton img {
    width: 18px;
    height: 18px;
}
