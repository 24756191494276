.active {
    color: #543fd6;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.stateButton {
    display: flex;
    gap: 1vw;
    cursor: pointer;
}
