.backGround {
    width: 80vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.promptContainer {
    margin-top: 2vh;

    display: grid;
    flex-wrap: wrap;
    gap: 3vw;
    justify-content: space-between;
}

.promptContainer.threeItems {
    grid-template-columns: repeat(3, 1fr); /* 한 줄에 3개씩 */
}

.promptContainer.twoItems {
    grid-template-columns: repeat(2, 1fr); /* 한 줄에 2개씩 */
}
