.container {
    width: 100%;
    display: flex;
    flex-direction: column;

    gap: 30px;
}

.guideContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.stepContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--block-main-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentText {
    word-break: break-word;
    white-space: normal;
    flex: 1;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.stepImage {
    margin: 0 auto;
}

@media (max-width: 1300px) {
    .stepImage {
        width: 150px;
        margin: 0 auto;
    }

    .stepContainer {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, auto);
        gap: 20px;
    }
}
