.formGroup {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.select {
    width: 100%;
}

.options {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
    gap: 12px;
}

.option {
    list-style-type: none;
    padding: 8px 15px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.input {
    box-sizing: border-box;
    background-color: var(--color-gray2);
    border: none;
    padding: 15px 18px;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    caret-color: var(--block-main-color);
}

.blockDescription {
    box-sizing: border-box;
    background-color: var(--color-gray2);
    padding: 15px 18px;
    margin-bottom: 15px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    height: 100px;
    resize: vertical;
    caret-color: var(--block-main-color);
}
