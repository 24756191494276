.content {
    margin-left: 390px;
    overflow-y: auto;
    height: 92.5vh;
}

.container {
    height: 100%;
}
.mobileMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
}
