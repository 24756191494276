.messagesContainer {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    padding-right: 20%;
    padding-left: 20%;
    min-width: 600px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
}

.messagesContainer::-webkit-scrollbar {
    width: 10px;
}
.messagesContainer::-webkit-scrollbar-thumb {
    background-color: #543fd6;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
}
.messagesContainer::-webkit-scrollbar-track {
    background-color: rgba(84, 63, 214, 0.5);
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
}

.message {
    display: inline-flex;
    height: auto;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px;
    background-color: var(--color-gray2);
    color: var(--gray-8);
    padding: 10px 15px;
    width: auto;
    max-width: 70%;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
}

.imageContainer {
    display: inline-flex;
    width: auto;
    height: auto;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-end;
    margin: 0;
    gap: 5px;
}

.image {
    width: 200px;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    margin: 0;
}

.fileName {
    margin: 0;
    white-space: nowrap;
    overflow: visible;
}

.sendMessage {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    margin: 0;
}

.promaChattingProfile {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px var(--color-gray3);
}

.receiveMessage {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    margin: 30px 0;
}

.receiveMessageText {
    margin-top: 12px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    line-height: 22px;
}
