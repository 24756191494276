.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* 반투명한 배경 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1105;
    overflow: hidden;
}

.container {
    display: flex;
    flex-direction: column;
    justify-items: left;

    padding: 20px;

    width: 600px;
    height: 700px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    z-index: 1106;
}

.topSection {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
}

.modalTitle {
    grid-column: 2;
    justify-self: center;
}

.closeButton {
    grid-column: 3;
    justify-self: end;
    width: fit-content;
    cursor: pointer;
}

.blockSection {
    margin: 20px auto;
    margin-bottom: auto;

    width: auto;
    height: auto;

    justify-content: center;
    justify-items: center;
    text-align: center;
}

.titleSection {
    display: flex;
    flex-direction: column;
}

.buttonSection {
    display: flex;
    justify-items: center;

    margin-top: 20px;
}

.inputTextBox {
    margin-top: 5px;
    margin-bottom: 5px;

    border: none;
    border-radius: 10px;
    background: #f4f4f4;

    padding: 10px;

    color: #393939;

    /* b3 */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.inputTextBoxEx {
    resize: none;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 150px;

    border: none;
    border-radius: 10px;
    background: #F4F4F4;

    padding: 10px;

    color: #393939;

    /* b3 */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.inputTextBoxEx::-webkit-scrollbar {
    width: 10px;
}
.inputTextBoxEx::-webkit-scrollbar-thumb {
    background-color: #543fd6;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
}
.inputTextBoxEx::-webkit-scrollbar-track {
    background-color: rgba(84, 63, 214, 0.5);
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
}
