.container {
    width: 100%;
}

.toggleContainer {
    display: flex;
    padding: 15px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.toggleSwitch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 22px;
}

.toggleSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-gray5);
    transition:
        background-color 0.2s,
        transform 0.2s;
    border-radius: 30px;
}
.toggleSlider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: var(--white);
    transition: transform 0.2s;
    border-radius: 50%;
}

.checkBox {
    opacity: 0;
    width: 0;
    height: 0;
}

.checkBox:checked + .toggleSlider {
    background-color: var(--block-main-color);
}

.checkBox:checked + .toggleSlider:before {
    transform: translateX(22px);
}
