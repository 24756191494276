.container {
    position: relative;
    margin-bottom: 10px;
    margin-left: 15px;
    display: inline-flex;
    width: auto;
    height: auto;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: var(--white);
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    box-shadow: 0 0 10px rgba(185, 127, 240, 0.8);
}

.containerActive {
    background-color: none;
    color: black;
    box-shadow: 0 0 15px rgba(138, 43, 226, 0.6);
    animation: glowing 1s ease-in-out infinite alternate;
}

@keyframes glowing {
    from {
        box-shadow: 0 0 25px rgba(184, 125, 239, 0.8);
    }
    to {
        box-shadow: 0 0 25px rgba(138, 43, 226, 0.8);
    }
}

.iconContainer {
    width: 15px;
    height: 15px;
}

.text {
    margin: 0;
    transition: color 0.3s ease;
}
.container .text {
    color: rgb(0, 0, 0);
}
.containerActive .text {
    color: rgb(0, 0, 0);
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.clearIcon {
    width: 16px;
    cursor: pointer;
    transition:
        transform 0.2s ease,
        filter 0.3s ease;
}

.containerActive .clearIcon {
    color: red;
}

.clearIcon:hover {
    transform: scale(1.1);
}
