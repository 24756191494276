.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.dataContainer td {
    border: 1px solid var(--color-gray4);
}

.tableContainer {
    width: 100%;
    border: 1px solid var(--color-gray4);
    border-collapse: collapse;
    border-left: none;
    border-right: none;
}

.codeBlock {
    padding: 0px 10px;
    font-size: 0.9rem;
    overflow-x: auto;
    white-space: pre-wrap;
}

.response {
    background-color: var(--color-gray2);
}

.contentContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.annotation {
    font-size: 14px;
    margin: 5px;
}
