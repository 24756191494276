.searchBarSection {
    margin-top: 15vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

.searchBar {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    padding: 10px 20px;
    width: 700px;
    height: 45px;
    margin: 3vh;
    border-radius: 100px;
    border: 1px solid #000000;
    background: #fff;
    box-shadow: 0 0.5vw 1vh 0 rgba(242, 242, 242, 0.88);

    color: #5a5a5a;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.sortSection {
    display: flex;
    justify-content: center;
}

.methodSection {
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.methodSection select {
    padding: 1vh 0.8vw;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.buttonSection {
    margin-right: auto;
    margin-left: auto;

    display: flex;
    width: 65vw;
    padding: 1vw;
    justify-content: space-between;

    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.writeButton {
    display: flex;
    padding: 0.5vh 1vw;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    border-radius: 20px;
    border: 1.5px solid #5a5a5a;
    background: #fff;

    color: #5a5a5a;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
