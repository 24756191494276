.container {
    width: 120px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border-radius: 30px;
    background-color: var(--color-gray1);
    transition: 0.2s;
}

.containerSelected {
    background-color: var(--logo-purple1);
}

.container:hover {
    transform: scale(1.1);
}

.icon {
    width: 30px;
}
