.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* 반투명한 배경 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 20px;

    width: 550px;
    height: 160px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    z-index: 1102;
}

.buttonSection {
    display: flex;
    gap: 1vw;
    margin-left: auto;
}
