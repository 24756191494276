.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* 반투명한 배경 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
    overflow: hidden;
}

.container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    text-align: center;

    width: 400px;
    height: 400px;
    padding: 2vw;

    z-index: 1101;

    border-radius: 1vw;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.topSection {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
}

.modalTitle {
    grid-column: 2;
    justify-self: center;
}

.closeButton {
    grid-column: 3;
    justify-self: end;
    width: fit-content;
    cursor: pointer;
}

.explainContainer {
    margin-top: 1vh;
}

.listSection {
    margin-top: 1vh;
}
