.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    margin: 50px;
    margin-top: 100px;
}
.container .logo {
    width: 15%;
    min-width: 150px;
    margin-bottom: 20px;
}
.container img {
    width: 18%;
    min-width: 250px;
}
.container .login:hover {
    transform: scale(1.05); /* 호버 시 약간 확대 효과 */
}

.socialLoginContainer {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
