.container {
    display: flex;
    justify-content: space-between; /* 요소 사이 공간을 최대로 벌림 */
    align-items: center;
    width: auto;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    cursor: pointer;
    padding: 15px;
}

.IconNName {
    display: flex;
    flex-direction: row;
    align-items: center;
}
