.container {
    position: relative;
    display: inline-block;
}

.button {
    display: flex;
    align-items: center;
    gap: 5px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
    color: black;
    transition: color 0.3s ease;
    font-weight: 500;
}

.button:hover {
    color: #007bff;
}

.button svg {
    transition: transform 0.3s ease;
}

.button .rotated {
    transform: rotate(180deg);
}

.expandableSection {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: auto;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}

.menuItem {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 15px;
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s ease;
    text-align: left;
    border: none;
    min-width: 100px;
    width: auto;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    background: none;
    white-space: nowrap;
}

.menuItem:hover {
    background-color: #f5f5f5;
}

.logoutButton {
    color: #dc3545;
    font-weight: bold;
    width: 100%;
}

@media (max-width: 768px) {
    .expandableSection {
        position: static;
        border: none;
        box-shadow: none;
        padding: 0;
    }

    .menuItem {
        padding: 10px;
    }
}
