.iconContainer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.menuContainer {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;
    position: absolute;
    background-color: var(--white);
    border-radius: 13px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    box-sizing: border-box;
    padding: 5px;
}

.menuButtonContainer {
    width: auto;
    height: auto;
    border-radius: 8px;
    padding: 10px 15px;
}

.menuButtonContainer:hover {
    background-color: var(--color-gray2);
}

.menu {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    white-space: nowrap;
}

.icon {
    width: 18px;
}
