.container {
    display: flex;

    padding: 10px 5px 10px 3px;
    align-items: center;

    border: 0;
    border-radius: 0.5vw;
    background: #f7f7f7;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);

    margin: 0.5vh;
}

.active {
    display: flex;

    padding: 10px 5px 10px 3px;
    align-items: center;

    border-radius: 0.5vw;
    background: #e1e2ff;
    box-shadow: 0px 0px 5px 0px rgba(84, 63, 214, 0.4);
}

.titleContainer {
    margin-left: 0.3vw;

    color: #5a5a5a;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
