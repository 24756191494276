.categoryContainer {
    display: flex;
    gap: 10px;
    margin: 1vh;

    align-items: center;
    justify-content: center;
}

.categoryButton {
    border-radius: 10px;
    border: 1.5px solid #5a5a5a;
    padding: 5px 10px;
    background-color: #fff;
    cursor: pointer;

    color: #5a5a5a;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.active {
    border-radius: 10px;
    border: 1px solid #543fd6;
    background-color: #543fd6;

    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
